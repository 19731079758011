import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { useCallback, useMemo } from "react";
import { chainConfig, clientId } from "../constant/Web3Auth";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { WALLET_ADAPTERS } from "@web3auth/base";

const useWeb3Auth = () => {
  const privateKeyProvider = useMemo(() => {
    return new EthereumPrivateKeyProvider({
      config: { chainConfig: chainConfig },
    });
  }, []);

  const initialize = useCallback(async () => {
    const web3Auth = new Web3Auth({
      clientId: clientId,
      web3AuthNetwork: "sapphire_devnet",
      privateKeyProvider: privateKeyProvider,
      enableLogging: true,
      chainConfig: chainConfig,
      uiConfig: {
        mode: "dark",
        loginGridCol: 3,
        primaryButton: "emailLogin",
        uxMode: "redirect",
      },
    });

    const openLoginAdapter = new OpenloginAdapter({
      adapterSettings: {
        uxMode: "redirect",
      },
    });

    web3Auth.configureAdapter(openLoginAdapter);

    await web3Auth.initModal({
      modalConfig: {
        [WALLET_ADAPTERS.OPENLOGIN]: {
          label: "openlogin",
          loginMethods: {
            apple: {
              name: "facebook",
              showOnModal: false,
            },
            reddit: {
              name: "reddit",
              showOnModal: false,
            },
            line: {
              name: "line",
              showOnModal: false,
            },
            github: {
              name: "github",
              showOnModal: false,
            },
            wechat: {
              name: "wechat",
              showOnModal: false,
            },
            twitter: {
              name: "twitter",
              showOnModal: false,
            },
            kakao: {
              name: "kakao",
              showOnModal: false,
            },
            linkedin: {
              name: "linkedin",
              showOnModal: false,
            },
            weibo: {
              name: "weibo",
              showOnModal: false,
            },
            google: {
              name: "google",
              showOnModal: false,
            },
            discord: {
              name: "discord",
              showOnModal: false,
            },
            twitch: {
              name: "twitch",
              showOnModal: false,
            },
            facebook: {
              name: "facebook",
              showOnModal: false,
            },
            email_passwordless: {
              name: "email_passwordless",
              showOnModal: true,
            },
            sms_passwordless: {
              name: "sms_passwordless",
              showOnModal: false,
            },
            farcaster: {
              name: "farcaster",
              showOnModal: false,
            },
          },
        },
        [WALLET_ADAPTERS.TORUS_EVM]: {
          label: "torus",
          showOnModal: false,
        },
        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
          label: "walletc",
          showOnModal: false,
        },
      },
    });

    return web3Auth;
  }, [privateKeyProvider]);

  return { initialize };
};

export default useWeb3Auth;
