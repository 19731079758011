import { createTheme } from "@mui/material";
import { COLORS } from "./colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#718355",
      contrastText: COLORS.whiteWithOpacity,
    },
    secondary: {
      main: COLORS.magenta,
    },
    text: {
      primary: COLORS.white,
    },
    background: {
      default: COLORS.background,
    },
  },
  typography: {
    fontFamily: "open-sans",
    h1: {
      fontFamily: "oopen-sans-extra",
      marginBlock: "none",
    },
    h3: {
      fontFamily: "open-sans-bold",
      marginBlock: "none",
    },
    body1: {
      fontFamily: "open-sans",
      marginBlock: "none",
    },
  },
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1440,
      md: 900,
      sm: 500,
      xs: 260,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          paddingLeft: "20px",
          paddingRight: "20px",
          borderWidth: "2px",
          borderRadius: "4px",
          height: "35px",
          width: "fit-content",
          marginTop: 0,
          color: "blue",
          borderColor: "blue",
          backfaceVisibility: "hidden",
          ".MuiTypography-root": {
            fontSize: "14px",
            fontFamily: "PoppinsSemibold",
          },
        },
      },
    },
  },
});
