import { Typography, styled } from "@mui/material";

export const AppTitle = styled(Typography)(({ theme }) => ({
  color: "#718355",
  fontFamily: "open-sans-extra",
  lineHeight: "56px",
  fontSize: "48px",
  // opacity: '0.8',
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {},
}));

export const ProjectTitle = styled(Typography)(({ theme }) => ({
  color: "#718355",
  fontFamily: "open-sans-bold",
  lineHeight: "42px",
  fontSize: "32px",
  // opacity: '0.8',
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {},
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: "#718355",
  fontFamily: "open-sans",
  lineHeight: "28px",
  fontSize: "16px",
  // opacity: '0.8',
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {},
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  color: "#718355",
  fontFamily: "open-sans-bold",
  lineHeight: "32px",
  fontSize: "22px",
  // opacity: '0.8',
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {},
}));
