import { CHAIN_NAMESPACES } from "@web3auth/base";

export const chainConfig = {
  chainId: "0x13881",
  rpcTarget:
    "https://still-proud-lake.matic-testnet.quiknode.pro/fde86eb3cabe1cd15797fd87a2bec3c1a5d37c3c/",
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  displayName: "Polygon Mumbai",
  blockExplorerUrl: "https://mumbai.polygonscan.com/",
  ticker: "Mumbai",
  tickerName: "Mumbai",
  logo: "https://images.toruswallet.io/polygon.svg",
};

export const clientId =
  "BFkZRH2_h9k-AyQVEByYzc_zIhsuN89OC0owF02kakRDG0MOy_0CVgDYvxmVUBOnZr88DpxxAO_kfVNbwxbJOWQ";
