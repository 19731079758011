import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { Metadata } from "../types/projectTypes";
import { CardTitle, Description } from "./styled/StyledTypo";

type Props = {
  cards: Metadata[];
};

const WalletCards: FC<Props> = ({ cards }) => {
  if (cards.length === 0 || !cards) {
    return <Description>Sajnos nincs még ilyen emléked.</Description>;
  }

  return (
    <Grid container spacing={2}>
      {cards.map((card: Metadata) => (
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              boxShadow: "0px 3px 8px 0px rgba(71,70,71,0.63)",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                "& img": {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <img src={card.image} alt="" />
            </Box>
            <Box
              sx={{
                p: "12px",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <CardTitle>{card.name}</CardTitle>
              <Description>{card.description}</Description>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default WalletCards;
