import { Box } from "@mui/material";
import { FC } from "react";
import { ProjectTitle } from "./styled/StyledTypo";

type Props = {
  bannerUrl: string;
  name: string;
};

const BannerSection: FC<Props> = ({ bannerUrl, name }) => {
  return (
    <Box mb={"30px"}>
      <Box
        sx={{
          mb: "20px",
          maxWidth: "100%",
          "& img": {
            width: "100%",
            height: "auto",
          },
        }}
      >
        <img src={bannerUrl} alt="" />
      </Box>
      <ProjectTitle>{name}</ProjectTitle>
    </Box>
  );
};

export default BannerSection;
