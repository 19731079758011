import { Box, Button, Container } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useStoreContext } from "../hook/useContext";
import { fetchFullWallet } from "../services/contractService";
import { ProjectWithMetada } from "../types/projectTypes";
import BannerSection from "./BannerSection";
import WalletCards from "./WalletCards";
import { AppTitle } from "./styled/StyledTypo";

type Props = {
  setuserIsLoggedIn: (val: boolean) => void;
};

const LoggedInView: FC<Props> = ({ setuserIsLoggedIn }) => {
  const [projectWithMedatas, setprojectWithMedatas] = useState<
    ProjectWithMetada[] | undefined
  >();

  const { store } = useStoreContext();

  const logout = () => {
    localStorage.clear();
    store.web3Auth.web3AuthInstance?.logout();
    window.location.reload();
    setuserIsLoggedIn(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const projectsWithMetadas = await fetchFullWallet(store.user.address);
      setprojectWithMedatas(projectsWithMetadas);
    };
    fetch();
  }, [store.user.address]);

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          pt: "20px",
        }}
      >
        <Button variant="contained" onClick={logout}>
          Kijelentkezés
        </Button>
        {/* <Description>Email: {store.user.email}</Description>
        <Description>Digitális pénztárca: {store.user.address}</Description> */}
      </Box>
      <Box my={"30px"}>
        <AppTitle>Az eddig gyűjtött emlékeid</AppTitle>
      </Box>
      {projectWithMedatas && projectWithMedatas.length > 0 && (
        <Box>
          {projectWithMedatas.map((project) => (
            <Box mb={"30px"}>
              <BannerSection
                bannerUrl={project.bannerUrl}
                name={project.name}
              />
              <WalletCards cards={project.cards} />
            </Box>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default React.memo(LoggedInView);
