import { User } from "../types/userTypes";
import { web3Auth } from "../types/web3Types";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import useWeb3Auth from "../hook/useWeb3Auth";

export interface StoreContextModel {
  store: StoreModel;
  setStore: Dispatch<SetStateAction<StoreModel>>;
}

export interface StoreModel {
  web3Auth: web3Auth;
  user: User;
}

export const StoreContext = createContext<StoreContextModel>(
  {} as StoreContextModel
);

type Props = {
  children: ReactNode;
};

export const initialState: StoreModel = {
  web3Auth: {
    web3AuthInstance: null,
    web3AuthProvider: null,
    isInitialized: false,
  },
  user: {
    address: "",
    email: "",
  },
};

export const StoreProvider: FC<Props> = ({ children }) => {
  const [store, setStore] = useState<StoreModel>(initialState);
  const { initialize } = useWeb3Auth();

  useEffect(() => {
    const initWeb3Auth = async () => {
      try {
        const web3AuthInstance = await initialize();
        setStore((prevStore) => ({
          ...prevStore,
          web3Auth: {
            ...prevStore.web3Auth,
            web3AuthInstance: web3AuthInstance,
            isInitialized: true,
          },
        }));
      } catch (error) {
        console.error("Error initializing Web3Auth:", error);
      }
    };
    initWeb3Auth();
  }, [initialize]);

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
