import { IProvider } from "@web3auth/base";
import axios from "axios";
import { Contract, JsonRpcProvider, ethers } from "ethers";
import masterRegistryAbi from "../abi/masterRegistry.json";
import tokenAbi from "../abi/token.json";
import { Metadata, Project, ProjectWithMetada } from "../types/projectTypes";

export const fetchFullWallet = async (ownerAddress: string) => {
  const projectsWithMetadatas: ProjectWithMetada[] = [];
  try {
    const rpcProvider = new JsonRpcProvider(
      "https://still-proud-lake.matic-testnet.quiknode.pro/fde86eb3cabe1cd15797fd87a2bec3c1a5d37c3c/"
    );

    const masterRegistry = getMasterRegistry(rpcProvider);

    console.log(masterRegistry);

    const projectCount = await masterRegistry.projectCount();

    console.log(Number(projectCount));

    for (let index = 0; index < Number(projectCount); index++) {
      const project = (await masterRegistry.projects(index)) as Project;
      console.log(project);

      const metadatas = await fetchUserWallet(
        project.tokenAddress,
        ownerAddress
      );

      projectsWithMetadatas.push({
        name: project.name,
        bannerUrl: project.bannerUrl,
        visible: project.visible,
        cards: [...metadatas],
      });
    }
    return projectsWithMetadatas;
  } catch (error) {
    console.log(error);
  }
};

const getMasterRegistry = (rpc: JsonRpcProvider) => {
  const masterRegistry = new Contract(
    "0x55611D3a1dC50DD0447932401D61F80904c79E6e",
    masterRegistryAbi,
    rpc
  );
  return masterRegistry;
};

export const getAccounts = async (
  provider: IProvider,
  web3AuthInstance: any
) => {
  if (provider) {
    const browserProvider = new ethers.BrowserProvider(provider);
    const userInfo = await web3AuthInstance?.getUserInfo();
    const accounts = await browserProvider.listAccounts();

    return { address: accounts[0].address, email: userInfo?.email };
  }
};

const fetchUserWallet = async (tokenAddress: string, ownerAddress: string) => {
  const tokenMetadatas: Metadata[] = [];
  const rpcProvider = new JsonRpcProvider(
    "https://still-proud-lake.matic-testnet.quiknode.pro/fde86eb3cabe1cd15797fd87a2bec3c1a5d37c3c/"
  );
  const tokenContract = new Contract(tokenAddress, tokenAbi, rpcProvider);

  const balance = await tokenContract.balanceOf(ownerAddress);

  for (let index = 0; index < Number(balance); index++) {
    const tokenOfOwnerByIndex = Number(
      await tokenContract.tokenOfOwnerByIndex(ownerAddress, index)
    );
    console.log(tokenOfOwnerByIndex);

    const tokenUri = await tokenContract.tokenURI(tokenOfOwnerByIndex);
    console.log(tokenUri);

    const metadata = await axios
      .get<Metadata>(tokenUri)
      .then((resp) => resp.data);

    tokenMetadatas.push(metadata);
  }
  return tokenMetadatas;
};
