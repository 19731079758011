import { ToastContainer } from "react-toast";
import "./App.css";
import MainUi from "./components/MainUi";
import StoreProvider from "./context/web3auth";
import { ThemeProvider } from "@mui/material";
import { theme } from "./utils/mui/mui.theme";

function App() {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <MainUi />
        <ToastContainer delay={8000} position="bottom-right" />
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
