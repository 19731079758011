import { ethers } from "ethers";
import { FC, useCallback, useEffect } from "react";
import { toast } from "react-toast";
import { useStoreContext } from "../hook/useContext";
import axios from "axios";

type Props = {
  setuserIsLoggedIn: (val: boolean) => void;
};

const LoginView: FC<Props> = ({ setuserIsLoggedIn }) => {
  const { store, setStore } = useStoreContext();

  useEffect(() => {
    const updateHeader = () => {
      const element = document.querySelector(".w3a-header__subtitle");
      const title = document.querySelector(".w3a-header__title");
      if (!element || !title) {
        setTimeout(updateHeader, 100);
        return;
      }
      element.innerHTML = "Ird be az email cimedet!";
      title.innerHTML = "Bejelentkezes";
    };

    const timeout = setTimeout(updateHeader, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const afterConnected = useCallback(
    async (web3AuthProvider: ethers.Eip1193Provider) => {
      const provider = new ethers.BrowserProvider(web3AuthProvider);
      const userInfo = await store?.web3Auth?.web3AuthInstance?.getUserInfo();
      const accounts = await provider.listAccounts();
      setStore((prevState) => ({
        ...prevState,
        user: {
          email: userInfo?.email || "",
          address: accounts[0]?.address || "",
        },
        web3Auth: {
          ...prevState.web3Auth,
          web3AuthProvider: provider,
        },
      }));

      if (userInfo && userInfo.email && accounts[0].address) {
        const userData = {
          email: userInfo.email,
          address: accounts[0].address,
        };
        setuserIsLoggedIn(true);
        axios.post(
          "https://kulton-2024-poi-backend-f017744bfdbf.herokuapp.com/poi/user/authentication",
          userData
        );
      } else {
        const waitForWeb3authModal = setTimeout(() => {
          setuserIsLoggedIn(true);
        }, 3000);
        return () => {
          clearTimeout(waitForWeb3authModal);
        };
      }
    },
    [setStore, setuserIsLoggedIn, store?.web3Auth?.web3AuthInstance]
  );

  const checkConnection = useCallback(async () => {
    try {
      localStorage.setItem("signIn", "OK");
      const web3AuthProvider = await store.web3Auth.web3AuthInstance?.connect();
      afterConnected(web3AuthProvider as ethers.Eip1193Provider);
    } catch (e) {
      console.log(e);
      // @ts-ignore
      if (e.code === -32602) {
        toast.error("Please change the network to Polygon");
      }
      setTimeout(() => {
        checkConnection();
      }, 3000);
    }
  }, [afterConnected, store.web3Auth.web3AuthInstance]);

  useEffect(() => {
    if (!store.web3Auth.isInitialized) return;
    checkConnection();
  }, [store.web3Auth.isInitialized, checkConnection]);

  return <></>;
};

export default LoginView;
