import React, { useState } from "react";
import LoggedInView from "./LoggedInView";
import LoginView from "./LoginView";

const MainUi = () => {
  const [userIsLoggedIn, setuserIsLoggedIn] = useState(false);

  return (
    <div>
      {userIsLoggedIn ? (
        <LoggedInView setuserIsLoggedIn={setuserIsLoggedIn} />
      ) : (
        <LoginView setuserIsLoggedIn={setuserIsLoggedIn} />
      )}
    </div>
  );
};

export default MainUi;
